<template>
	<div>
		<div class="container darkBack">
			<!-- 上方徒步人数表 -->
			<div>
				<div class="whiteFont font18">徒步</div>
				<div id="footNum"></div>
			</div>
			<!-- 中间运动年龄分布图 -->
			<div class="age">
				<div class="blueFont">● 运动年龄分布</div>
				<div id="footAgeDistribution"></div>
			</div>
			<!-- 下方男女比例 -->
			<div>
				<div class="blueFont">● 男女比例</div>
				<div class="sex">
					<div class="imgGroup">
						<img class="manPic" src="../../../assets/images/man1.png" >
						<img class="womanPic" src="../../../assets/images/woman1.png" >
					</div>
					<div class="legendText">
						<span>男</span>
						<span>女</span>
					</div>
					<div class="proportion">
						<div class="man">{{ratio.man}}</div>
						<div class="woman">{{ratio.woman}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				ratio:{
					man:"",
					woman:""	
				},
				running:{
					yAxis:[],
					data:[],
				},
				age:{
					data:[]
				}
			}
		},
		methods:{
			// 绘制跑步人数表
			drawFootNum(number){
				let that = this;
				// let myChart = this.$echarts.init(document.getElementById("footNum"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("footNum"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("footNum"));
				}
				
				let option = {
					xAxis: {
						max: 'dataMax',
						// 显示网格线
						splitLine:{
							show: true,
							lineStyle:{
								color:'#EAB03133',
							}
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '12%', 
						top:'8%', 
						bottom: '8%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.yAxis,
						inverse: true,
						animationDuration: 300,
						animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:true,
							lineStyle:{
								color:'#EAB031',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:"0.14rem"
						},
					},
					series: [{
						// 实时排序
						realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'40%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						label: {
							show: true,
							position: 'right',
							color:"#F1F1F1",
							valueAnimation: true,
                            fontSize:'0.14rem',
						},
						itemStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#E5813A" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#EFDC29" // 100% 处的颜色
								}], false)
							}
						},
					}],
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 绘制运动年龄分布图
			drawFootAgeDistribution(number){
				// let myChart = this.$echarts.init(document.getElementById("footAgeDistribution"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("footAgeDistribution"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("footAgeDistribution"));
				}
								
				let option = {
					legend: {
						orient:'vertical',
						bottom:'center',
						right:30,
						height:150,
						itemWidth:15,
						itemHeight:15,
						itemGap:15,
						textStyle: { //图例文字的样式
							color: '#7EA7E2',
							fontSize: '0.14rem'
						},
					},
					series: [
						{
							type: 'pie',
							radius: ['40%', '50%'],
							 // 在容器中的位置
							center:['30%','50%'],
							avoidLabelOverlap: false,
							labelLine: {
								show: true,
								//设置延长线的长度
								normal: {
									length: 15,//设置延长线的长度
									length2: 10,//设置第二段延长线的长度
									lineStyle: {
										color: "#ffffff"  // 改变标示线的颜色
									}
								},
							}, 
							 // 字体颜色及大小
							label:{
								color:'#D2F8FF',
								textStyle:{fontSize:'0.12rem'},
							},
							color:['#1D77D2','#E35D68','#1BBA79','#F45E23'],
							data: number.data
						}
					]
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			getRunAge(){
				let url = window.appHost+"/api/v1/screen/Outdoors/user_analysis?plate_type=3"
        		let result = window.ajaxRequest(url)
				// console.log(result)
				this.ratio.man=result.data.sex[0].parent+"%"
				this.ratio.woman=result.data.sex[1].parent+"%"
				this.age.data=[]
				for(let i in result.data.age){
				this.age.data.push({
					value:result.data.age[i].parent,
					name:result.data.age[i].title
				});
				this.drawFootAgeDistribution(this.age);
				}
			},
			getOutDoorData(){
				let url = window.appHost+"/api/v1/screen/Outdoors/area?plate_type=3"
        		let result = window.ajaxRequest(url)
				// console.log(result)
				this.running={
						yAxis:[],
						data:[]
				}
				for(key in result.data){
					// console.log(key)
					// console.log(result.data[key])
					this.running.yAxis.push(key)
					this.running.data.push(result.data[key])
				}
				// console.log(this.running)
				this.drawFootNum(this.running);
			}
		},
		mounted() {
			this.getOutDoorData()
			// this.drawFootNum(this.running);
			this.getRunAge()
		}
	}
</script>

<style scoped>
	.container{
		width: 4.37rem;
		height: 7rem;
		padding: 0.2rem;
		position: relative;
	}
	#footNum{
		width:4.00rem;
		height: 2.40rem;
		/* border:1px solid red; */
	}
	#footAgeDistribution{
		width: 4.10rem;
		height: 1.80rem;
	}
	.sex{
		width: 3.02rem;
		margin-left: 11%;
		margin-top: 0.2rem;
	}
	.imgGroup{
		display: flex;
		justify-content: space-between;
	}
	.manPic{
		width: 0.42rem;
		height: 0.48rem;
	}
	.womanPic{
		width: 0.46rem;
		height: 0.49rem;
	}
	.legendText{
		display: flex;
		justify-content: space-between;
		color: #7B8CB7;
		margin-top: 0.1rem;
	}
	.proportion{
		width: 3.02rem;
		height: 0.35rem;
		background: url(../../../assets/images/sexBorder.png) no-repeat;
		background-size: 100% 100%;
		margin-top: 0.2rem;
		display: flex;
		align-items: center;
		padding: 0.2rem 0.1rem 0.2rem 0.1rem;
		box-sizing:border-box;
	}
	.man , .woman{
		height: 0.28rem;
		line-height: 0.28rem;
		color: white;
		text-align: center;
	}
	.man{
		width: 1.5rem;
		background-color: #0C68A1;
	}
	.woman{
		width: 1.5rem;
		background-color: #C77828;
	}
</style>